import {FocusMonitor} from '@angular/cdk/a11y';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  Directive,
  ElementRef,
  inject,
  Input,
  NgZone,
  ViewChild,
} from '@angular/core';
import {takeUntil} from 'rxjs/operators';

import {SEAZONE_CONTROL_CONFIG} from '@controls/shared';
import {DestroySubscription} from '@helpers/destroy-subscription';

import {ControlStatus} from '../form-errors/form-errors';
import {FormFieldHintPosition, SeazoneFormFieldI, SeazoneFormFieldComponent} from '../seazone-form-field';

export interface SeazoneFormFieldWrapperI extends SeazoneFormFieldI {
  wrapperStyling: string[];
}

@Directive()
export abstract class SeazoneFormFieldWrapper extends DestroySubscription implements SeazoneFormFieldWrapperI {

  wrapperStyling: string[] = [];
  status: ControlStatus = 'valid';
  isRequired = false;

  @Input() label: string | null = null;
  @Input() readonly = false;
  @Input() large = false;
  @Input() disabled = false;
  @Input() placeholder: string;
  @Input() hint = null;
  @Input() hintPosition: FormFieldHintPosition = 'top';

  @Input()
  set required(isRequired: BooleanInput) {
    this.isRequired = coerceBooleanProperty(isRequired);
  }

  get required(): boolean {
    return this.isRequired;
  }

  @ViewChild('formInput', {static: true}) formInput: ElementRef<HTMLInputElement>;
  @ViewChild('formField', {static: true}) formField: SeazoneFormFieldComponent;

  readonly seazoneControlSettings = inject(SEAZONE_CONTROL_CONFIG);
  protected readonly zone = inject(NgZone);
  protected readonly focusMonitor = inject(FocusMonitor);

  constructor() {
    super();
    this.updateStyling();
  }

  protected updateStyling(): void {
    const classList: string[] = [];
    const settings = this.seazoneControlSettings;
    if (settings.direction === 'row') {
      classList.push('form__block--row');
    }
    this.wrapperStyling = [...classList, ...settings.additionalClasses || ''];
  }

  activateInput(): void {
    this.formInput.nativeElement.focus();
  }

  abstract onTouched(): void;

  onChangeStatus(event: ControlStatus): void {
    if (this.status !== event) {
      this.status = event;
    }
  }

  protected checkFocus(): void {
    this.focusMonitor.monitor(this.formInput).pipe(
      takeUntil(this.destroyStream$),
    ).subscribe(origin => {
      this.zone.run(() => {
        if (!this.formField) {
          return;
        }
        this.formField.setActiveState(!!origin);
      });
    });
  }
}
